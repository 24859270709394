import { useEffect, useState } from "react";
import {
  ReusableButton,
  ModalAsulado,
  HeadTitle,
  Container,
  DynamicTable,
  PaginationAsulado,
  GoEye,
  FaCheck,
  LoaderAsulado,
} from "@mfe/js-asulado-uiutils";
import { postService, API } from "@mfe/ts-asulado-utilitaries";
import { navigateToUrl } from "single-spa";

const columnsTablaPensionados = [
  { key: "nombre", label: "Nombre" },
  { key: "idDocumento", label: "Tipo identificación" },
  { key: "valorPension", label: "Valor pensión", isMoney: true },
  { key: "valorDescuentos", label: "Valor descuentos", isMoney: true },
  { key: "valorTotal", label: "Valor total", isMoney: true },
];

const columnsTablaDetalleDescuentos = [
  { key: "descripcionConcepto", label: "Descripción" },
  { key: "valorDescuento", label: "Valor", isMoney: true },
];

const rowsPerPage = 7;

export const Aprobacion = () => {
  const [tokenApi, setTokenApi] = useState({
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [datosPensionados, setDatosPensionados] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalApproveIsOpen, setModalApproveIsOpen] = useState(false);
  const [modalDetailIsOpen, setModalDetailIsOpen] = useState(false);
  const [detalleDescuentosPensionado, setDetalleDescuentosPensionado] =
    useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = datosPensionados.slice(startIndex, endIndex);
  const totalPages = Math.ceil(datosPensionados.length / rowsPerPage);

  const closeModalApprove = () => setModalApproveIsOpen(false);
  const closeModalDetail = () => setModalDetailIsOpen(false);

  const fetchQueryDatosPensionados = async () => {
    const storedData = localStorage.getItem("filtroSeleccionado");

    if (storedData) {
      const data = JSON.parse(storedData);

      try {
        const respuesta = await postService(
          `${API.simulacion}liquidapensionados`,
          data,
          tokenApi
        );

        if (typeof respuesta === "object") {
          setIsLoading(false);
          setDatosPensionados(respuesta);
        } else {
          console.error(`Error al listar pensionados: ${respuesta}`);
        }
      } catch (error) {
        console.error("La consulta falló:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchQueryDatosPensionados();
  }, []);

  const handleViewDetail = (row) => {
    const data = {
      pen_codigo: row.codigoPensionado.toString(),
      liq_codigo: row.codigoLiquidacion.toString(),
    };

    const fetchQueryResults = async () => {
      try {
        const respuesta = await postService(
          `${API.simulacion}listarDetalleDescuentos`,
          data,
          tokenApi
        );

        if (typeof respuesta === "object") {
          setDetalleDescuentosPensionado(respuesta);
        } else {
          console.error(`Error al listar detalle descuentos: ${respuesta}`);
        }
      } catch (error) {
        console.error("La consulta falló:", error);
      }
    };
    fetchQueryResults();

    setModalDetailIsOpen(true);
    setSelectedRow(row);
  };

  const handleApprove = (row) => {
    setSelectedRow(row);
    setModalApproveIsOpen(true);
  };

  const handleClickNext = () => {
    navigateToUrl("/app#detalles");
  };

  const handleClickReturn = () => {
    navigateToUrl("/app#busqueda");
  };

  const handleClickConsulta = () => {
    const data = [
      {
        codigoPensionado: "",
        codigoLiquidacion: selectedRow.codigoLiquidacion.toString(),
      },
    ];

    const fetchQueryResults = async () => {
      try {
        const respuesta = await postService(
          `${API.simulacion}aprobarLiq`,
          data,
          tokenApi
        );

        if (typeof respuesta === "object") {
          fetchQueryDatosPensionados();
          closeModalApprove();
        } else {
          console.error(`Error al aprobar pensionado: ${respuesta}`);
        }
      } catch (error) {
        console.error("La consulta falló:", error);
      }
    };
    fetchQueryResults();
  };

  return (
    <Container sx={{ maxWidth: "80%" }} size="xl">
      <HeadTitle title="Aprobación pensionados" />
      {isLoading ? (
        <div style={{ textAlign: "center", marginTop: 15 }}>
          <LoaderAsulado></LoaderAsulado>
        </div>
      ) : (
        <div>
          <DynamicTable
            className={"table-colors"}
            columns={columnsTablaPensionados}
            data={paginatedData}
            actions={"Acciones"}
            typeActions={[
              {
                metodo: handleViewDetail,
                icon: <GoEye size={20} />,
              },
              {
                metodo: handleApprove,
                icon: <FaCheck size={20} />,
              },
            ]}
          >
            {/* Children */}
            <div className="pagination">
              <PaginationAsulado
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            </div>
          </DynamicTable>

          <div
            style={{
              textAlign: "end",
              marginTop: "20px",
              display: "flex",
              justifyContent: "end",
              gap: 15,
            }}
          >
            <ReusableButton
              text="Volver"
              onClick={handleClickReturn}
              className="submit-btn"
            />
            <ReusableButton
              text="Continuar"
              onClick={handleClickNext}
              className="submit-btn"
            />
          </div>
          {/* <ReusableButton text="Disabled" onClick={handleClick} disabled={true} /> */}

          {modalApproveIsOpen && (
            <ModalAsulado
              rowData={selectedRow}
              title={
                <div
                  style={{
                    textAlign: "center",
                    margin: "1em 2em 2em",
                    fontSize: 22,
                  }}
                >
                  ¿ Estas seguro que deseas aprobar ?
                </div>
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2em",
                }}
              >
                <ReusableButton
                  text="Cancelar"
                  onClick={closeModalApprove}
                  className="submit-btn-cancel"
                />
                <ReusableButton
                  text="Aceptar"
                  onClick={handleClickConsulta}
                  className="submit-btn"
                />
              </div>
            </ModalAsulado>
          )}

          {modalDetailIsOpen && detalleDescuentosPensionado.length > 0 && (
            <ModalAsulado
              rowData={selectedRow}
              title={
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 22,
                  }}
                >
                  Descuentos pensionado
                  <hr />
                </div>
              }
            >
              <DynamicTable
                columns={columnsTablaDetalleDescuentos}
                data={detalleDescuentosPensionado}
              ></DynamicTable>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2em",
                  marginTop: 30,
                }}
              >
                <ReusableButton
                  text="Cerrar"
                  onClick={closeModalDetail}
                  className="submit-btn"
                />
              </div>
            </ModalAsulado>
          )}
        </div>
      )}
    </Container>
  );
};
